import { useCallback, useState } from 'react';

import { AxiosService, ErrorResponse } from '@/api/axiosService';

export type Params = { [key: string]: string };

interface QueryParams {
  [key: string]: string | number | boolean;
}

interface IUseService<T, K> {
  client: AxiosService;
  headers?: { [key: string]: string };
  mapper: (data: T) => K;
  params?: Params;
  url: string;
}

interface FetchOptions {
  body?: any;
  cache?: boolean;
  method?: 'GET' | 'POST';
  queryParams?: QueryParams;
}

export const useService = <T, K>({ client, url, headers, params, mapper }: IUseService<T, K>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();
  const [data, setData] = useState<K | undefined>();

  const fetch = useCallback(
    async (options?: FetchOptions) => {
      const { method = 'GET', body, cache, queryParams } = options || {};
      try {
        setIsLoading(true);
        let responseData;
        const payload = {
          endpoint: url,
          cache,
          params,
          queryParams,
          config: { headers },
        };
        if (method === 'GET') {
          responseData = await client.get<T>(payload);
        } else if (method === 'POST') {
          responseData = await client.post<T, K>({ ...payload, data: body });
        } else {
          throw new Error(`Unsupported method: ${method}`);
        }
        setData(mapper(<T>responseData));
      } catch (e) {
        const error = e as ErrorResponse;
        console.log(error);
        setError(new Error('Network response was not ok'));
      } finally {
        setIsLoading(false);
      }
    },
    [client, url, params, headers, mapper],
  );

  return {
    data,
    error,
    fetch,
    isLoading,
  };
};
