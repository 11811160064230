import { hasECSpace, isExclusiveCollection } from '@/helpers/product';

export const mapProduct = (product: APIProduct): Product => {
  const { comfort, description_2, media, resort_id, title, type } = product;

  return {
    id: resort_id,
    description: description_2,
    map: media.map,
    name: title,
    comfort: {
      hasExclusiveSpace: hasECSpace(product),
      isExclusiveCollection: isExclusiveCollection({ level: comfort?.level, type: type.id }),
      level: comfort?.level,
    },
    country: product.destination?.countries?.[0],
  };
};
