import { Icon } from '@clubmed/trident-icons';
import { FunctionComponent, HTMLAttributes, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { SegmentedControl } from '@/components/segmented-control/SegmentedControl';
import { useExperience } from '@/contexts/ExperienceContext';
import { classnames } from '@/helpers/classnames';

const EXCLUSIVE_VALUE = 'exclusive';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  comfort: ProductConfort;
  onChange?: (value: string) => void;
}

export const ExperienceSwitch: FunctionComponent<Props> = ({
  className,
  comfort,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const { setIsECMode } = useExperience();
  const id = useId();

  if (!comfort.hasExclusiveSpace) {
    return null;
  }

  const handleChange = (value: string) => {
    setIsECMode(value === EXCLUSIVE_VALUE);
    onChange?.(value);
  };

  return (
    <div {...props} className={classnames('space-y-8', className)}>
      <p className="text-center text-b3" id={id}>
        {t('Text:text.switchAndSeeTheLuxuryExperience')}
      </p>
      <SegmentedControl
        aria-labelledby={id}
        initialValue="resort"
        name="space-switch"
        onChange={handleChange}
        options={[
          { label: t('Buttons:title.resort'), value: 'resort' },
          {
            activeClassName: 'text-white',
            indicatorClassName: 'bg-ultramarine',
            label: (
              <>
                <Icon width="24px" name="Diamond" />
                {t('Buttons:title.exclusiveSpace')}
              </>
            ),
            value: EXCLUSIVE_VALUE,
          },
        ]}
      />
    </div>
  );
};
