import { FunctionComponent } from 'react';

import { LanguagesForm } from '@/components/languages-form/LanguagesForm';
import { PageContainer } from '@/components/page-container/PageContainer';
import { TrackPage } from '@/components/track-page/TrackPage';
import { TRACK_PAGE_NAMES } from '@/constants/tracking';
import { useApp } from '@/contexts/AppContext';

export const LanguageSelectorPage: FunctionComponent = () => {
  const { locale, productId } = useApp();

  return (
    <main className="flex h-screen w-full items-center justify-center bg-lightSand" role="main">
      <TrackPage
        locale={locale}
        pageName={TRACK_PAGE_NAMES.LANGUAGE_PAGE}
        params={{ resort_code: productId }}
      />
      <PageContainer className="max-w-[380px]">
        <LanguagesForm />
      </PageContainer>
    </main>
  );
};
