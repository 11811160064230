/* eslint-disable react-hooks/exhaustive-deps */
import { addDays } from 'date-fns';
import { FunctionComponent, HTMLAttributes, useEffect } from 'react';

import { PLANNING_ENDPOINT } from '@/api/APIClient';
import { mapProductPlanning } from '@/api/product-planning/productPlanningMapper';
import { Program } from '@/components/program/Program';
import { Skeleton } from '@/components/skeleton/Skeleton';
import { useApp } from '@/contexts/AppContext';
import { formatDateToAPIDateTime } from '@/helpers/dates';
import { useAPI } from '@/hooks/use-service/useAPI';

export const ProductProgram: FunctionComponent<HTMLAttributes<HTMLDivElement>> = (props) => {
  const { productId } = useApp();

  const from = new Date();
  from.setHours(0, 0, 0, 0);
  const to = addDays(from, 6);

  const {
    data: dressCodes,
    error,
    fetch,
    isLoading,
  } = useAPI({ mapper: mapProductPlanning, url: PLANNING_ENDPOINT });

  useEffect(() => {
    if (productId) {
      fetch({
        queryParams: {
          date_format: 'LEGACY',
          first_date: formatDateToAPIDateTime(from),
          last_date: formatDateToAPIDateTime(to),
          type: 'DRESSCODE',
        },
      });
    }
  }, [fetch]);

  if (isLoading) {
    return <Skeleton className="aspect-square h-[370px] w-full rounded-16 md:w-[370px]" />;
  }

  if (error || !dressCodes) {
    return null;
  }

  return <Program {...props} dressCodes={dressCodes} />;
};
